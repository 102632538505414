exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-api-tsx": () => import("./../../../src/pages/about/api.tsx" /* webpackChunkName: "component---src-pages-about-api-tsx" */),
  "component---src-pages-about-methods-tsx": () => import("./../../../src/pages/about/methods.tsx" /* webpackChunkName: "component---src-pages-about-methods-tsx" */),
  "component---src-pages-about-overview-tsx": () => import("./../../../src/pages/about/overview.tsx" /* webpackChunkName: "component---src-pages-about-overview-tsx" */),
  "component---src-pages-about-user-guide-tsx": () => import("./../../../src/pages/about/user-guide.tsx" /* webpackChunkName: "component---src-pages-about-user-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */),
  "component---src-templates-topic-tsx": () => import("./../../../src/templates/topic.tsx" /* webpackChunkName: "component---src-templates-topic-tsx" */),
  "component---src-templates-treaty-tsx": () => import("./../../../src/templates/treaty.tsx" /* webpackChunkName: "component---src-templates-treaty-tsx" */)
}

